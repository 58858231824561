import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Cover from "../components/cover.js"
import SEO from "../components/seo"
import styles from "./projects.module.styl"
import { Link } from "gatsby"

const Projects = props => {
  const { pageContext, data } = props
  const { previousPagePath, nextPagePath } = pageContext
  const currentYear = new Date().getFullYear()

  return (
    <Layout page="projects">
      <SEO
        title="Projects"
        description="设计师蔡依东作品集，包含相宜本草……"
        pathname="/projects/"
      />
      <main className={styles.main}>
        <span className={styles.moreee}>2009-{currentYear}-moreee</span>

        {data.allProjectsJson.edges.map((obj, index) => (
          <Cover key={index} {...obj.node} />
        ))}

        <div className={styles.page}>
          {previousPagePath ? (
            <Link to={previousPagePath} className={styles.pagePre}>
              Previous
            </Link>
          ) : null}
          {nextPagePath ? (
            <Link to={nextPagePath} className={styles.pageNext}>
              Next
            </Link>
          ) : null}
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allProjectsJson(skip: $skip, limit: $limit) {
      edges {
        node {
          color
          href
          title
          title_en
          coverClass
          img {
            childImageSharp {
              fixed(height: 600, quality: 100) {
                ...GatsbyImageSharpFixed
              }
              sizes {
                src
                srcSet
                sizes
              }
            }
            publicURL
          }
          video {
            publicURL
          }
          poster
        }
      }
    }
  }
`

export default Projects
